<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    this.redirect()
  },
  methods: {
    redirect() {
      let { redirect_uri: redirectUri, lineuid_param: uidParam, ...query } = this.$route.query
      if (!uidParam) {
        uidParam = "line_uid"
      }
      if (redirectUri.indexOf('?') !== -1) {
        redirectUri = `${redirectUri}&${uidParam}=${this.$store.state.liffAuth.user.customer_code}`
      } else {
        redirectUri = `${redirectUri}?${uidParam}=${this.$store.state.liffAuth.user.customer_code}`
      }

      for (const [key, value] of Object.entries(query)) {
        redirectUri = `${redirectUri}&${key}=${value}`
      }
      window.location.href = redirectUri
    }
  }
}
</script>
