<template>
  <div></div>
</template>

<script>
import https from "../../apis/liff/v2/https";
export default {
  mounted() {
    this.redirect()
  },
  methods: {
    async redirect() {
      let fullPathAfter = '';
      let params = new URL(location.href).searchParams;

      if (params.get('liff.state')) {
        fullPathAfter = params.get('liff.state');
      } else {
        // 把後面網址全部截下來
        const fullUrl = window.location.href;
        const url = new URL(fullUrl);
        const path = url.pathname;
        const pathArray = path.split('/');
        const pathIndex = pathArray.indexOf('old-version-redirect');
        const pathAfter = pathArray.slice(pathIndex + 1).join('/');
        const queryString = url.search;
        fullPathAfter = pathAfter + queryString
      }

      try {
        let response = await https.get(`/${this.$route.params.orgCode}/liff/liff-init-module`);

        if (! response.data?.data?.liff_id) {
          throw 'API 沒有提供 LIFF ID';
        }

        if (fullPathAfter.startsWith('/')) {
          fullPathAfter = fullPathAfter.slice(1);
        }

        const fullRedirectUrl = `https://liff.line.me/${response.data.data.liff_id}/${fullPathAfter}`
        console.log(fullRedirectUrl);
        window.location.href = fullRedirectUrl;
      } catch (error) {
        console.error(error)
        alert('網址錯誤');
      }
    }
  }
}
</script>
